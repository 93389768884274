import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class extends Controller {
  connect () {
    gsap.registerPlugin(ScrollTrigger);

    const scrollTriggerParams = {
        trigger: ".gsap-home-patate",
        start: "top center",
        end: "bottom 100px",
        scrub: true,
    }
    gsap.fromTo(".gsap-guest-patate-1", {
      rotate: 0,
      y: -50
    }, {
      y: 300,
      rotate: 100,
      scrollTrigger: {
        ...scrollTriggerParams
      }
    });
    gsap.fromTo(".gsap-guest-patate-2", {
      rotate: 0,
      y: 100
    }, {
      y: -200,
      rotate: 100,
      scrollTrigger: {
        ...scrollTriggerParams
      }
    });

    gsap.fromTo(".gsap-guest-patate-3", {
      y: -100
    }, {
      y: 300,
      scrollTrigger: {
        ...scrollTriggerParams
      }
    });
    gsap.fromTo(".gsap-guest-patate-4", {
      y: 200
    }, {
      y: -200,
      scrollTrigger: {
        ...scrollTriggerParams
      }
    });

    gsap.fromTo(".gsap-guest-patate-5", {
      y: -400
    }, {
      y: 100,
      scrollTrigger: {
        ...scrollTriggerParams
      }
    });

    gsap.fromTo(".gsap-guest-patate-6", {
      y: -400
    }, {
      y: 100,
      scrollTrigger: {
        ...scrollTriggerParams
      }
    });
    gsap.fromTo(".gsap-guest-patate-7", {
      y: -100
    }, {
      y: 100,
      scrollTrigger: {
        ...scrollTriggerParams
      }
    });
    gsap.fromTo(".gsap-guest-patate-8", {
      y: -500
    }, {
      y: 100,
      scrollTrigger: {
        ...scrollTriggerParams
      }
    });
  }
}