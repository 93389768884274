import { Controller } from "@hotwired/stimulus";
import Swiper, { EffectFade, Navigation, Pagination } from "swiper";

export default class extends Controller {
    initialize() {}
    connect() {
        const mainSlider = new Swiper(".js-main-slider", {
            modules: [Pagination],
            slidesPerView: 1,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    const newIndew = (index + 1).toString().padStart(2, "0");
                    return (
                        '<span class="' +
                        className +
                        '">' +
                        newIndew +
                        "</span>"
                    );
                },
            },
        });

        const frequentStepSlider = new Swiper(".js-frequent-steps-slider", {
            slidesPerView: 2,
            loop: true,
            spaceBetween: 40,
            centeredSlides: true,
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                992: {
                    centeredSlides: false,
                    spaceBetween: 20,
                    slidesPerView: 5,
                },
                // 1200: {},
                // 1400: {},
            },
        });

        const discoverOnlineServices = new Swiper(".js-discover-online-services-slider", {
            slidesPerView: 2,
            loop: true,
            spaceBetween: 40,
            centeredSlides: true,
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                992: {
                    centeredSlides: false,
                    spaceBetween: 20,
                    slidesPerView: 5,
                },
                // 1200: {
                //     centeredSlides: false,
                //     loop: false,
                //     slidesPerView: 5,
                // },
                // 1400: {},
            },
        });

        const refundHelpSlider = new Swiper(".js-refund-help-slider", {
            slidesPerView: "auto",
            spaceBetween: 30,
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 4,
                },
                // 1200: {},
                // 1400: {},
            },
        });

        const lifeEventSlider = new Swiper(".js-life-event-slider", {
            modules: [Navigation, Pagination],

            slidesPerView: 1,
            spaceBetween: 0,
            centeredSlides: true,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                768: {
                    loop: false,
                    centeredSlides: false,
                    slidesPerView: 3,
                },
            },
        });

        const seeFrequentsQuestions = new Swiper(".js-see-frequents-questions-slider", {
            modules: [Navigation],

            slidesPerView: 1,
            spaceBetween: 40,
            setWrapperSize: 130,
            // centeredSlides: true=,
            center: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                576: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
            },
        });

        const cpsCloseToYouSlider = new Swiper(".js-cps-close-to-you-slider", {
            modules: [Navigation, Pagination],
            centeredSlides: true,
            // slidesPerView: "auto",
            loop: true,
            slidesPerView: 2,
            spaceBetween: 20,
            breakpoints: {
                768: {
                    centeredSlides: false,
                    slidesPerView: 3,
                    loop: false,
                },
            },
        });

        const ourLatestPressReleaseSlider = new Swiper(
            ".js-our-latest-press-release-slider",
            {
                modules: [Navigation, Pagination, EffectFade],
                effect: "fade",
                dynamicBullets: true,
                preloadImages: true,
                slidesPerView: 1,
                spaceBetween: 0,
                centeredSlides: true,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            }
        );
    }
}
