import { Controller } from "@hotwired/stimulus";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class extends Controller {
  connect () {
    gsap.registerPlugin(ScrollTrigger);

    gsap.fromTo(".gsap-home-patate", {
      rotate: 0,
    }, {
      x: 50,
      rotate: 100,
      scrollTrigger: {
        trigger: ".gsap-home-patate",
        start: "top center",
        end: "bottom 100px",
        scrub: true,
      }
    });

    gsap.fromTo(".gsap-parallax-header", {
      y: 100,
    }, {
      y: 400,
      scrollTrigger: {
        trigger: ".gsap-parallax-header",
        start: "top center",
        end: "bottom 0",
        scrub: true,
      }
    });
  }
}